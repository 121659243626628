import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Form, Tooltip, OverlayTrigger } from "react-bootstrap"
import graph from "../../images/topic-2/section-3/graph-1.svg"
import graph1 from "../../images/topic-2/section-3/graph-5.svg"
import graph2 from "../../images/topic-2/section-3/graph-10.svg"
import graph3 from "../../images/topic-2/section-3/graph-20.svg"
import graph4 from "../../images/topic-2/section-3/graph-30.svg"
import graph5 from "../../images/topic-2/section-3/graph-40.svg"
import graph6 from "../../images/topic-2/section-3/graph-r.svg"
import graph7 from "../../images/topic-2/section-3/graph-r-half.svg"
import fish from "../../images/topic-2/section-3/fish-1.jpg"
import fish1 from "../../images/topic-2/section-3/fish-5.jpg"
import fish2 from "../../images/topic-2/section-3/fish-10.jpg"
import fish3 from "../../images/topic-2/section-3/fish-20.jpg"
import fish4 from "../../images/topic-2/section-3/fish-30.jpg"
import fish5 from "../../images/topic-2/section-3/fish-40.jpg"
import equation1 from "../../images/equations/logistic-equation-1.svg"
import equation2 from "../../images/equations/r-equation-faded-1.svg"
import equation3 from "../../images/equations/r-equation-faded-2.svg"
import equation4 from "../../images/equations/r-equation.svg"
import {
  faQuestionCircle,
  faImage,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonLink from "../../components/buttonLink"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import RespMsg from "../../components/respMsg"
import RadioQuestion from "../../components/radioQuestion"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const LogGrowthSection3 = ({ location }) => {
  const { t } = useTranslation()

  const renderTooltipK = props => (
    <Tooltip id="button-tooltip" {...props}>
      {parse(t("Topic2_Section3_tooltip[0]"))}
    </Tooltip>
  )

  const renderTooltipRmax = props => (
    <Tooltip id="button-tooltip" {...props}>
      {parse(t("Topic2_Section3_tooltip[1]"))}
    </Tooltip>
  )

  const pageContent = {
    breadcrumb: parse(t("Topic2_Section3_breadcrumb[1]")),
    title: parse(t("Topic2_Section3_title")),
    topTexts: [
      parse(t("Topic2_Section3_topText[0]")),
      parse(t("Topic2_Section3_topText[1]")),
      parse(t("Topic2_Section3_topText[2]")),
      parse(t("Topic2_Section3_topText[3]")),
      parse(t("Topic2_Section3_topText[4]")),
      parse(t("Topic2_Section3_topText[5]")),
      parse(t("Topic2_Section3_topText[6]")),
    ],
    bottomTexts: [
      parse(t("Topic2_Section3_bottomText[0]")),
      parse(t("Topic2_Section3_bottomText[1]")),
      parse(t("Topic2_Section3_bottomText[2]")),
      parse(t("Topic2_Section3_bottomText[3]")),
      parse(t("Topic2_Section3_bottomText[4]")),
      //5
      [
        parse(t("Topic2_Section3_bottomText[5][0]")),
        <span>
          {parse(t("Topic2_Section3_bottomText[5][1]"))}
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipK}
          >
            <span className="popup bold">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
          </OverlayTrigger>{" "}
        </span>,
      ],
      //6
      <span>
        <p className="bold">
          {parse(t("Topic2_Section3_bottomText[6][0]"))}
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipRmax}
          >
            <span className="popup bold">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
          </OverlayTrigger>{" "}
        </p>
        {parse(t("Topic2_Section3_bottomText[6][1]"))}
      </span>,
    ],
    images: [
      //0
      {
        src: equation1,
        alt: parse(t("Topic2_Section3_images[0].alt")),
        text: parse(t("Topic2_Section3_images[0].caption")),
        fishes: [
          {
            src: "",
            alt: "",
            text: "",
          },
        ],
      },
      //1
      {
        fishes: [
          {
            src: fish,
            alt: parse(t("Topic2_Section3_images[1].fishes[0].alt")),
            text: parse(t("Topic2_Section3_images[1].fishes[0].caption")),
          },
          {
            src: fish1,
            alt: parse(t("Topic2_Section3_images[1].fishes[1].alt")),
            text: parse(t("Topic2_Section3_images[1].fishes[1].caption")),
          },
          {
            src: fish2,
            alt: parse(t("Topic2_Section3_images[1].fishes[2].alt")),
            text: parse(t("Topic2_Section3_images[1].fishes[2].caption")),
          },
          {
            src: fish3,
            alt: parse(t("Topic2_Section3_images[1].fishes[3].alt")),
            text: parse(t("Topic2_Section3_images[1].fishes[3].caption")),
          },
          {
            src: fish4,
            alt: parse(t("Topic2_Section3_images[1].fishes[4].alt")),
            text: parse(t("Topic2_Section3_images[1].fishes[4].caption")),
          },
          {
            src: fish5,
            alt: parse(t("Topic2_Section3_images[1].fishes[5].alt")),
            text: parse(t("Topic2_Section3_images[1].fishes[5].caption")),
          },
        ],
        graphs: [
          {
            src: graph,
            alt: parse(t("Topic2_Section3_images[1].graphs[0].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[0].caption")),
          },
          {
            src: graph1,
            alt: parse(t("Topic2_Section3_images[1].graphs[1].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[1].caption")),
          },
          {
            src: graph2,
            alt: parse(t("Topic2_Section3_images[1].graphs[2].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[2].caption")),
          },
          {
            src: graph3,
            alt: parse(t("Topic2_Section3_images[1].graphs[3].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[3].caption")),
          },
          {
            src: graph4,
            alt: parse(t("Topic2_Section3_images[1].graphs[4].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[4].caption")),
          },
          {
            src: graph5,
            alt: parse(t("Topic2_Section3_images[1].graphs[5].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[5].caption")),
          },
          {
            src: graph6,
            alt: parse(t("Topic2_Section3_images[1].graphs[6].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[6].caption")),
          },
          {
            src: graph7,
            alt: parse(t("Topic2_Section3_images[1].graphs[7].alt")),
            text: parse(t("Topic2_Section3_images[1].graphs[7].caption")),
          },
        ],
      },
      //2
      { src: "", alt: "" },
      //3
      { src: "", alt: "" },
      //4
      { src: "", alt: "" },
      //5
      { src: "", alt: "" },
      //6
      { src: "", alt: "" },
    ],
    topicsTitle: "",
    instructions: [
      parse(t("Topic2_Section3_instructions[0]")),
      parse(t("Topic2_Section3_instructions[1]")),
      parse(t("Topic2_Section3_instructions[2]")),
      parse(t("Topic2_Section3_instructions[3]")),
      parse(t("Topic2_Section3_instructions[4]")),
      parse(t("Topic2_Section3_instructions[5]")),
      parse(t("Topic2_Section3_instructions[6]")),
    ],
    radios: [
      {
        label: parse(t("Topic2_Section3_radios[0].label")),
        answer: false,
        msg: parse(t("Topic2_Section3_radios[0].msg")),
      },
      {
        label: parse(t("Topic2_Section3_radios[1].label")),
        answer: false,
        msg: parse(t("Topic2_Section3_radios[1].msg")),
      },
      {
        label: parse(t("Topic2_Section3_radios[2].label")),
        answer: true,
        msg: (
          <Row>
            <Col sm="8">{parse(t("Topic2_Section3_radios[2].msg"))}</Col>
            <Col sm="4">
              <img
                src={graph6}
                alt={parse(t("Topic2_Section3_images[1].graphs[6].alt"))}
              />
              <p className="smText">
                {parse(t("Topic2_Section3_images[1].graphs[6].caption"))}
              </p>
            </Col>
          </Row>
        ),
      },
    ],
    stage4: {
      texts: [
        parse(t("Topic2_Section3_stage4.texts[0]")),
        parse(t("Topic2_Section3_stage4.texts[1]")),
        parse(t("Topic2_Section3_stage4.texts[2]")),
      ],
      images: [
        {
          src: equation2,
          alt: parse(t("Topic2_Section3_stage4.images[0].alt")),
        },
        {
          src: equation3,
          alt: parse(t("Topic2_Section3_stage4.images[1].alt")),
        },
        {
          src: equation4,
          alt: parse(t("Topic2_Section3_stage4.images[2].alt")),
        },
      ],
    },
    stage6: {
      answer: 25,
      errorMsg: parse(t("Topic2_Section3_stage6.errorMsg")),
      successMsg: (
        <Row>
          <Col sm="8">{parse(t("Topic2_Section3_stage6.successMsg"))}</Col>
          <Col sm="4">
            <img
              src={graph7}
              alt={parse(t("Topic2_Section3_images[1].graphs[7].alt"))}
            />
            <p className="smText">
              {parse(t("Topic2_Section3_images[1].graphs[7].caption"))}
            </p>
          </Col>
        </Row>
      ),
      finalErrorMsg: (
        <Row>
          <Col sm="8">{parse(t("Topic2_Section3_stage6.finalErrorMsg"))}</Col>
          <Col sm="4">
            <img
              src={graph7}
              alt={parse(t("Topic2_Section3_images[1].graphs[7].alt"))}
            />
            <p className="smText">
              {parse(t("Topic2_Section3_images[1].graphs[7].caption"))}
            </p>
          </Col>
        </Row>
      ),
    },
  }
  //===============================================================================
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [disableChoices, setDisableChoices] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true)
  const [stage, setStage] = useState(0)
  const [ccAnswer, setCCAnswer] = useState(0)
  const [errorCount, setErrorCount] = useState(0)
  const [fishClicks, setFishClicks] = useState(0)
  const [showFish, setShowFish] = useState(true)
  const [showRadioCheck, setShowRadioCheck] = useState(true)
  const [resetPlaceholder, setResetPlaceholder] = useState(false)

  //===============================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic2_Section3_pageTitle"))} />
      <MyBreadcrumb
        topicLink="logisticgrowth"
        topicTitle={parse(t("Topic2_Section3_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb}
      />
      <div className="homeContent">
        <Row>
          <Col sm="12">
            <TopHeader
              title={pageContent.title}
              content={pageContent.topTexts[stage]}
            />
          </Col>
        </Row>

        {/* STAGE 0 */}
        <div hidden={stage != 0}>
          <center>
            <img
              src={pageContent.images[stage].src}
              alt={pageContent.images[stage].alt}
              className="roundMe"
            />
            <p className="smText alignLeft">{pageContent.images[stage].text}</p>
          </center>{" "}
          {pageContent.bottomTexts[stage]}
        </div>

        {/* STAGE 1 */}
        <div hidden={stage != 1}>
          <Instructions content={pageContent.instructions[stage]} />

          <Row>
            <Col sm="6" md="8">
              <img
                src={
                  showFish
                    ? pageContent.images[1].fishes[fishClicks].src
                    : pageContent.images[1].graphs[fishClicks].src
                }
                alt={
                  showFish
                    ? pageContent.images[1].fishes[fishClicks].alt
                    : pageContent.images[1].graphs[fishClicks].alt
                }
                style={{ maxHeight: "400px" }}
              />
              <p className="smText alignLeft">
                {showFish
                  ? parse(t("Topic2_Section3_images[1].fishes[0].caption"))
                  : parse(t("Topic2_Section3_grapth.caption"))}
                <br />
                <br />
                <div className="alignLeft">
                  <ButtonLink
                    onClick={() => setShowFish(true)}
                    btnText={<FontAwesomeIcon icon={faImage} />}
                    // btnColor="grey"
                    className={showFish ? "graphBtnL active" : "graphBtnL"}
                  />
                  <ButtonLink
                    onClick={() => setShowFish(false)}
                    btnText={<FontAwesomeIcon icon={faChartLine} />}
                    // btnColor="grey"
                    className={!showFish ? "graphBtnR active" : "graphBtnR"}
                  />
                </div>
              </p>
            </Col>
            <Col sm="6" md="4">
              {" "}
              <table>
                <tr>
                  <th className="alignRight">
                    {parse(t("Topic2_Section3_tableHeader"))}
                  </th>
                  <th className="alignRight">[1-(N/K)]</th>
                </tr>
                <tr>
                  <td
                    className={
                      fishClicks == 1
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 1 ? "-" : "5"}
                  </td>
                  <td
                    className={
                      fishClicks == 1
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 1 ? "-" : "0.9"}
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      fishClicks == 2
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 2 ? "-" : "10"}
                  </td>
                  <td
                    className={
                      fishClicks == 2
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 2 ? "-" : "0.8"}
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      fishClicks == 3
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 3 ? "-" : "20"}
                  </td>
                  <td
                    className={
                      fishClicks == 3
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 3 ? "-" : "0.6"}
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      fishClicks == 4
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 4 ? "-" : "30"}
                  </td>
                  <td
                    className={
                      fishClicks == 4
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 4 ? "-" : "0.4"}
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      fishClicks == 5
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 5 ? "-" : "40"}
                  </td>
                  <td
                    className={
                      fishClicks == 5
                        ? "alignRight highlightTable"
                        : "alignRight"
                    }
                  >
                    {fishClicks < 5 ? "-" : "0.2"}
                  </td>
                </tr>
              </table>
              <div className="alignRight">
                <ButtonLink
                  onClick={() => {
                    setFishClicks(0)
                    setNextBtnEnabled(false)
                  }}
                  btnText={parse(t("Topic2_Section3_resetBtn"))}
                  // btnColor="grey"
                  className="btnPrev"
                  disabled={!fishClicks >= 1}
                />
                <ButtonLink
                  onClick={() => {
                    setFishClicks(fishClicks + 1)
                    if (fishClicks == 4) {
                      setNextBtnEnabled(true)
                    }
                  }}
                  btnText={parse(t("Topic2_Section3_addfishBtn"))}
                  // btnColor="grey"
                  className="btnNext"
                  disabled={fishClicks == 5}
                />
              </div>
              <br />
            </Col>
          </Row>
          <p>{pageContent.bottomTexts[stage]}</p>
        </div>
        {/* STAGE 2 */}
        <div hidden={stage != 2}></div>

        {/* STAGE 3 */}
        <div hidden={stage != 3}></div>

        {/* STAGE 4 */}
        <div hidden={stage != 4}>
          {pageContent.stage4.texts[0]}
          <center>
            {" "}
            <img
              src={pageContent.stage4.images[0].src}
              alt={pageContent.stage4.images[0].alt}
            />
          </center>
          {pageContent.stage4.texts[1]}
          <center>
            {" "}
            <img
              src={pageContent.stage4.images[1].src}
              alt={pageContent.stage4.images[1].alt}
            />
          </center>
          {pageContent.stage4.texts[2]}
          <center>
            {" "}
            <img
              src={pageContent.stage4.images[2].src}
              alt={pageContent.stage4.images[2].alt}
            />
          </center>
        </div>

        {/* STAGE 5 */}
        <div hidden={stage != 5}>
          {" "}
          <Instructions content={pageContent.instructions[stage]} />
          <p>{pageContent.bottomTexts[5][0]}</p>
          <center>
            {" "}
            <img
              src={pageContent.stage4.images[2].src}
              alt={pageContent.stage4.images[2].alt}
            />
          </center>
          <p className="bold">{pageContent.bottomTexts[5][1]}</p>
          <Form>
            <RadioQuestion
              data={[
                {
                  id: "radio1",
                  name: "formRadios",
                  label: pageContent.radios[0].label,
                  answer: pageContent.radios[0].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio2",
                  name: "formRadios",
                  label: pageContent.radios[1].label,
                  answer: pageContent.radios[1].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio3",
                  name: "formRadios",
                  label: pageContent.radios[2].label,
                  answer: pageContent.radios[2].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
              ]}
              // disabled={disableChoices == true}
              myOnClick={(answer, label, id) => {
                switch (id) {
                  case "radio1":
                    setMsgContent(pageContent.radios[0].msg)
                    break
                  case "radio2":
                    setMsgContent(pageContent.radios[1].msg)
                    break
                  case "radio3":
                    setMsgContent(pageContent.radios[2].msg)
                    break
                  default:
                    return null
                }
                if (answer == true) {
                  setMsgType("correct")
                } else {
                  setMsgType("error")
                }
                setShowMsg(true)
                setDisableChoices(answer)
                setNextBtnEnabled(answer)
              }}
              disabled={disableChoices}
            />
          </Form>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>

        {/* STAGE 6 */}
        <div hidden={stage != 6}>
          {" "}
          <Instructions content={pageContent.instructions[stage]} />
          <p>{pageContent.bottomTexts[6]}</p>
          <Row style={{ width: "50%" }}>
            <Col sm="4">
              {" "}
              <Form.Control
                placeholder="N"
                disabled={errorCount >= 4}
                onChange={e => setCCAnswer(e.target.value)}
                type="number"
                value={resetPlaceholder == true ? "" : null}
                // minlength="9"
                // maxlength="11"
              />
            </Col>
            <Col sm="8">
              {" "}
              <ButtonLink
                // btnLink="#top"
                btnWidth
                variant="secondary"
                btnText={parse(t("Submit"))}
                disabled={(errorCount >= 4) | nextBtnEnabled}
                onClick={() => {
                  if (ccAnswer != pageContent.stage6.answer) {
                    setMsgContent(pageContent.stage6.errorMsg)
                    setMsgType("error")
                  } else {
                    setMsgContent(pageContent.stage6.successMsg)
                    setMsgType("correct")
                    setNextBtnEnabled(true)
                  }
                  if (
                    errorCount >= 3 &&
                    ccAnswer != pageContent.stage6.answer
                  ) {
                    setMsgContent(pageContent.stage6.finalErrorMsg)
                    setMsgType("error")
                    setNextBtnEnabled(true)
                  }
                  setShowMsg(true)
                  setErrorCount(errorCount + 1)
                }}
                // btnColor="#4A331C"
              />
            </Col>
          </Row>
          <br />
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>

        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              onClickPrev={() => {
                switch (stage) {
                  case 1:
                    setStage(stage - 1)
                    setFishClicks(0)
                    setShowFish(true)
                    setNextBtnEnabled(true)
                    break
                  case 1:
                    setStage(stage - 1)
                    setNextBtnEnabled(true)
                    setShowMsg(false)
                    break
                  case 2:
                    setStage(stage - 1)
                    setNextBtnEnabled(false)
                    break
                  case 3:
                    setStage(stage - 1)
                    break
                  case 4:
                    setStage(stage - 1)
                    break
                  case 5:
                    setStage(stage - 1)
                    setShowMsg(false)
                    setMsgType("")
                    setMsgContent("")
                    setShowRadioCheck(false)
                    setDisableChoices(false)
                    setNextBtnEnabled(true)
                    setResetPlaceholder(false)
                    break
                  case 6:
                    setShowMsg(false)
                    setMsgType("")
                    setMsgContent("")
                    setErrorCount(0)
                    setStage(stage - 1)
                    setShowRadioCheck(true)
                    setResetPlaceholder(true)
                    setNextBtnEnabled(false)
                    break
                  default:
                    return null
                }
              }}
              prevName={parse(t("Previous"))}
              btnLinkPrev={
                stage == 0 ? "/logisticgrowth/section2" : "#myHeader"
              }
              disabled={nextBtnEnabled == false}
              nextName={parse(t("Next"))}
              btnLinkNext={
                stage == 6 ? "/logisticgrowth/section4" : "#myHeader"
              }
              onClickNext={() => {
                switch (stage) {
                  case 0:
                    setStage(stage + 1)
                    setNextBtnEnabled(false)
                    break
                  case 1:
                    setStage(stage - 1)
                    setFishClicks(0)
                    setShowFish(true)
                    setStage(stage + 1)
                    setNextBtnEnabled(true)
                    setShowMsg(false)
                    break
                  case 2:
                    setStage(stage + 1)
                    setNextBtnEnabled(true)
                    break
                  case 3:
                    setStage(stage + 1)
                    setNextBtnEnabled(true)
                    break
                  case 4:
                    setStage(stage + 1)
                    setNextBtnEnabled(false)
                    setShowRadioCheck(true)
                    break
                  case 5:
                    setStage(stage + 1)
                    setShowMsg(false)
                    setMsgType("")
                    setMsgContent("")
                    setErrorCount(0)
                    setNextBtnEnabled(false)
                    setShowRadioCheck(false)
                    setDisableChoices(false)
                    break
                  default:
                    return null
                }
              }}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default LogGrowthSection3
